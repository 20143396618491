<template>
  <q-btn>
    <slot></slot>
  </q-btn>
</template>

<script>
import { mapActions } from "vuex";

export default {
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },
  },
  methods: {
    ...mapActions("settings", ["getSettings"]),
  },
  
};
</script>

<style lang="scss" scoped>
.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
  background-color: v-bind("settings.color_primary");
}
</style>
